import { GeoPoint } from "../../../modules/Location/Entities";
import { MapMarkerImage } from "../MapMarkers";

/** Public / Application-meaningful properties for our Google Map component */
export interface GoogleMapProps {

    pickupLocation?: GeoPoint;

    dropoffLocation?: GeoPoint;

    /** Geopoint for the center of the map. When combined with [zoom], this sets the bounds. */
    mapCenter: GeoPoint;

    /** Google Maps zoom level. Big = zoomed in. */
    zoom?: number;

    /** Taxi number */
    carNumber: string | null;

    /** Location of taxi for status Accepted & Pickedup */
    vehicleLocation?: GeoPoint;

    /** Image URL and anchor point for the pickup location. This can be different in different contexts like Bookings and Saved addresses. */
    PickupMarker: MapMarkerImage;

    /** A route from the pickup to the dropoff, usually associated with a fare estimate. */
    PlannedRoute: google.maps.LatLngLiteral[] | null;

    /** The use case, e.g. Taxi Tracking vs Booking Widget. Controls some behaviours. */
    Purpose: MapPurpose;
}

/** This will help GoogleMapRaw customise some behaviours. */
export enum MapPurpose {

    /** A map linked to a booking under construction. The bounds / zoom are controlled by the component to keep the pickup and dropoff in view. */
    BookingWidget = "Booking Widget",

    /** A map for tracking the taxi. Usually just zoom in on the taxi. */
    TaxiTracking = "Taxi Tracking",

    /** A map to display a single location. */
    FavouriteAddress = "Favourite Address",
}