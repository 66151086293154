import { BrandedImage, GetBrandedUrl } from "../../modules/Utils/BrandedContentUrls";
import { ContentURL, getContentUrl } from "../../modules/Utils/ContentURL";

/** 
 *  Predefined image URLs and anchor points for standard images used as map markers on a Google Map.
 *  The goal is to align any arrow or origin point depicted in the image with the center of the location in the Google Map.
 */
export namespace MapMarkers {

    /** Pickup marker "new-map-marker-pickup.png" */
    export const PickupLocation: MapMarkerImage = {

        Url: GetBrandedUrl(BrandedImage.MapMarkerPickup),
        TargetPoint: {
            x: 31,
            y: 63,
        }
    };

    /** new-map-marker-dropoff.png is the same shape as the pickup marker */
    export const DropoffLocation: MapMarkerImage = {

        Url: getContentUrl(ContentURL.images.MapMarkerDropoff),
        TargetPoint: {
            x: 31,
            y: 63,
        }
    }

    /** generic-map-pin.png. For address points. */
    export const GenericLocation: MapMarkerImage = {
        Url: GetBrandedUrl(BrandedImage.GenericMapMarker),
        TargetPoint: {
            x: 25,
            y: 50,
        }
    }
}

/** The information needed to render an image-based map marker on the Google Map */
export interface MapMarkerImage {

    /** The URL for the map marker. Often some call to GetBrandedUrl() or getContentUrl(). */
    Url: string,

    /** The location of the "tip" of the marker in the image. This helps align it perfectly. */
    TargetPoint: PointLiteral,
}

interface PointLiteral {
    x: number;
    y: number;
}