import { TaxiFareEstimateResponseV2, TaxiFareResponseV2 } from "../../../Services/FareEntities";
import { VehicleOption } from "./ConditionEntities";
import { ConditionState } from "./ConditionState";
import { ServiceInfo, ServiceKind } from "../../../utils/service-vehicles/ServiceMetadata";
import { VehicleType } from "../../../Services/FixedFareEntities";

/** Mini-reducer for the ApplyFareEstimate action. It will transform the Conditions list by setting the Fares field. */
export function FareEstimateReducer(state: ConditionState, fares: TaxiFareResponseV2): ConditionState {

    const newSelectedCondition = UpdateVehicle(state.SelectedCondition, fares);
    const newConditionList = UpdateVehicleList(state.ConditionList, fares);

    return {
        ...state,
        SelectedCondition: newSelectedCondition,
        ConditionList: newConditionList,
        FareEstimate: fares.FareDetails,
        IsFixedFareAvailable: fares.IsFixedFareAvailable
    };
}

/**
 * Transform the Conditions list by setting the Fares field.
 * Existing Fare estimate V2 API provides a single fare value for the selected condition.
 * Temporarily, displaying same fare value for all the conditions in the Conditions list.
 */
export function FareEstimateReducerV2(state: ConditionState, fare: TaxiFareEstimateResponseV2): ConditionState {

    const taxiFare = fare.TotalFare.toString();

    const conditionListWithFare: VehicleOption[] = state.ConditionList.map((item) => {
        return {...item, Fare: taxiFare}
    });

    const selectedConditionWithFare = {...state.SelectedCondition, Fare: taxiFare};

    return {
        ...state,
        SelectedCondition: selectedConditionWithFare,
        ConditionList: conditionListWithFare
    }
}

/** Like UpdateVehicle, but for a whole array of VehiclePayload records. */
function UpdateVehicleList(vehicles: VehicleOption[], fares: TaxiFareResponseV2): VehicleOption[] {
    let result: VehicleOption[] = [];

    for (let vehicle of vehicles) {
        result.push(UpdateVehicle(vehicle, fares));
    }

    return result;
}

/** Given a VehiclePayload with empty fare value, return a new VehiclePayload with the corresponding fare set. */
function UpdateVehicle(vehicle: VehicleOption, fares: TaxiFareResponseV2): VehicleOption {
    const vehicleType = GetVehicleTypeFromService(vehicle.Service);
    if (!vehicleType) return vehicle;

    const fare = fares.FareDetails.Fare[vehicleType];
    if (!fare) return vehicle;

    const vehicleOption: VehicleOption = {
        ...vehicle,
        FareDetails: fare,
    };

    return vehicleOption;
}

/** Convert a Service (Kind) (which we get from Condition data) into a VehicleType (which Fare Estimates are expressed in). */
function GetVehicleTypeFromService(service: ServiceInfo): VehicleType | null {

    switch (service.kind) {
        case ServiceKind.MaxiTaxi:
            return VehicleType.MaxiTaxi;

        /**
         * Mapping from ServiceKind.Taxi --> VehicleType.Sedan is added; which was requested by business requirement;
         * This is to fix the mapping from condition (named "Taxi") to fare (named "Sedan"), specific for SA/Adelaide.
         */
        case ServiceKind.Sedan:
        case ServiceKind.NextAvailable:
        case ServiceKind.NextAvailableSouthAustralia:
        case ServiceKind.Taxi:
        case ServiceKind.Parcel:
        case ServiceKind.Limo:
            return VehicleType.Sedan;

        case ServiceKind.SilverService:
            return VehicleType.SilverService;

        case ServiceKind.StationWagon:
            return VehicleType.Wagon;

        case ServiceKind.Wheelchair:
            return VehicleType.Wheelchair;

        default:
            return null; // the only safe thing is not to display an estimate
    }
}