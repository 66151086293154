import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrl, Post } from './ServiceManager';
import { FareEstimateRequestV1, TaxiFareResponseV2 } from './FareEntities';

/** Base URL for the FareController in the Booking Management Service
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Fare/`;
}

/** HTTP API Calls to the FareController in the Booking Management Service. */
class FareServiceClass {

    /** Gets the fixed fare estimate, fare range, and a flag indicating whether fixed fare is available. */
    public GetFareV2(request: FareEstimateRequestV1): Promise<ServiceResult<TaxiFareResponseV2>> {
        const url = `${GetBaseUrl()}GetFareV2`;
        return Post<TaxiFareResponseV2>(url, request);
    }
}

/** Singleton FareService instance */
export const FareService = new FareServiceClass();